import * as MsalUtils from 'app/msal/utils';

export const environment = {
  production: false,
  cal: {
    auth: {
      clientId: '5b0c9cc5-9292-4c44-858c-ca7fc1929064',
      authority:
        'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
      redirectUri: `${location.protocol}//${location.hostname}${location.port && ':' + location.port}/`,
      navigateToLoginRequestUrl: true,
      validateAuthority: true,
      postLogoutRedirectUri: `${location.protocol}//${location.hostname}${location.port && ':' + location.port}/`
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: MsalUtils.isIE,
    },
    consentScopes: [
      'openid',
      'profile',
      'user.read',
      'https://mobilepssr-dev-cvx.azurewebsites.net/.default',
    ],
    protectedResourceMap: [
      [
        'https://graph.microsoft.com/oidc/userinfo',
        ['openid', 'profile', 'User.Read', 'offline_access'],
      ],
      ['https://mobilepssr-api-dev-cvx.azurewebsites.net/api', ['User.Read']],
    ],
  },
  apiEndpoint: 'https://mobilepssr-api-dev-cvx.azurewebsites.net/api',
  azureContainerEndpoint: 'mobilepssr-files-dev',
  env: 'dev',
};
